// Config
// ---------
// Use the same color all over the place? Need to do
// some math with height and width and text size?
// Sass supports variables as well as basic math
// operations and many useful functions.
//
// For complete documentation:
// http://sass-lang.com/docs/yardoc/file.SASS_REFERENCE.html#variables_
//
// Available functions:
// http://sass-lang.com/docs/yardoc/Sass/Script/Functions.html

// Available functions:
// SASS:				http://sass-lang.com/docs/yardoc/Sass/Script/Functions.html
// Bourbon:				http://bourbon.io/docs/
// Boubon Neat (grid):	https://github.com/thoughtbot/neat#using-the-grid

// Import variables and mixins to be used (Bourbon)
//@import "components/bourbon/bourbon"
//@import "components/neat/neat-helpers"
//
//


@use "sass:math"
//@import "bourbon"
//@import "neat"

// Bourbon grid display. Comment out to remove display.
$visual-grid: false
//true
$visual-grid-color: #EEEEEE

// Set to false if you'd like to remove the responsiveness.
$responsive: true

// Setup the column, grid, and gutter amounts.
$column: 60px
$gutter: 20px
$grid-columns: 22
$max-width: 1040px
$mobile-max-width: 710px
$tablet-max-width: 920px
$tablet-max-width2: 1020px
$wide-min-width: 1225px //$max-width + 1200px
$xtra-wide-min-width: 1450px
//em(1200)


$first_sidebar_width: 190px
$second_sidebar_width: 310px
//$desktop_width: 1100px
//$avatar-width-mobile: 35px
//$avatar-width-default: 50px
//$smallest-screen: 310px
//$label-width: 25%
$group-column-width: 320px

// Define your breakpoints.
$mobile: new-breakpoint(max-width $mobile-max-width 8)
$tablet: new-breakpoint(max-width $tablet-max-width 16)

// Import grid to be used (Bourbon Neat).
// We load this after we provide our overrides: https://github.com/thoughtbot/neat#changing-the-defaults
//@import "components/neat/neat"

// Import local files
@import "_variables.sass"
@import "_mixins.sass"
