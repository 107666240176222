@import "_config.sass";

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box
}

/* Layout
 * ------
 * Using a negative margin technique, adapted from ZEN. The page is loaded by this order:
 *
 * 1. Header
 * 2. Content
 * 3. Navigation menus
 * 4. Sidebar Left
 * 5. Sideabr Right */

/* remove 'auto' and the width to switch to a fluid width */
#page {
	width:100%;
	margin:0;
  max-width: 100%;
  clear: both;
}

/* Layout rules. (Disclaimer: do not change if you're not sure you know what you're doing.) */
#content {
  float: left;
  width: 100%;
  margin-right: -100%;
  padding: 0;
  clear: both;
}

.sidebar {
  float: left;
}

#sidebar-second {
  float: right;
}

#footer {
  float: none;
  clear: both;
}

/* Layout Helpers */
#header,
#footer,
.breadcrumb,
.node {
  clear: both;
}


/*#footer div.inner,
#pre-footer div.inner,
#pre-header div.inner,
nav div.inner,
header div.inner,*/
//.region-hero .userinfo-wrapper,
//.region-hero .fivestar-container,
nav div.inner{
  margin:0 auto;
}
#content-after .inner,
#pre-footer div.inner,
#banner div.inner,
#footer div.inner,
//#fold-inner,
#main{
  @include outer-container();

	width:100%;
	margin-top:0;
  margin-bottom: 0;
  padding: 0 calc($padding);
}

/* .region-hero .userinfo-wrapper{
  @include outer-container();
  //max-width: 1600px;
  max-width:$max-width;
  min-width:800px;
  width:100%;
  margin:0 auto;
  padding: 0;
} */

/*.modal{
  #crumb-bar-inner,
  #fold-inner,
  #header-inner,
  #main-inner,
  #footer-inner {
    min-width:inherit;
  }
}*/

/* Sidebars width
 * --------------
 * Changing the width of the sidebars is dead easy, just change the
 * values below corresponding to the sidebar you want to modify.
 * Make sure you keep negative values as negative values.
 * For example, if I want to increase the width of the left sidebar
 * to 300px, I would have to change each '190' to '300'. */

// Left value.
.two-sidebars,
.sidebar-first {
  .center {
    margin-left: $first_sidebar_width;
  }
}

// Left value & negative left value.
#sidebar-first {
  width: $first_sidebar_width;
  margin-right: -$first_sidebar_width;
}

.two-sidebars,
.sidebar-second {
  .center {
    margin-right: $second_sidebar_width;
  }
}

// Right value.
#sidebar-second {
  width: $second_sidebar_width;
}





/* Columns Inner
 * -------------
 * You can change the padding inside the columns without changing the
 * width of them by just using the INNER div of each column */
.inner {
  padding: 0;
}


/*

#navigation{
  li{
    list-style-type: none;
    display: inline-block;
  }
}

*/


